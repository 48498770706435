import classNames from "classnames";
import PropTypes from "prop-types";
import { useContext, useState } from "react";

import SVG from "@/components/elements/SvgIcon";
import Markdown from "@/components/types/Markdown";
import { LessonContext } from "../Lesson";
import { ReviewContext } from "../Review";
import { sendAttemptResult } from "../utils";
import cn from "./Quiz.module.css";

const setOrder = (options) => {
  const shuffled = options
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  return shuffled;
};

const Quiz = ({ id, index, quizJSON }) => {
  const {
    continueLesson = () => {},
    currentPart,
    isLoading: lessonIsLoading,
    setLoading: setLessonLoading,
  } = useContext(LessonContext);
  const {
    continueReview = () => {},
    currentIndex,
    isLoading: reviewIsLoading,
    setLoading: setReviewLoading,
  } = useContext(ReviewContext);

  const handleContinue = () => {
    continueLesson();
    continueReview();
  };

  const [choice, setChoice] = useState({
    value: "",
  });
  const [hasSubmitted, setSubmitted] = useState(false);

  const somethingIsLoading = lessonIsLoading || reviewIsLoading;

  const quiz = JSON.parse(quizJSON);
  const { question, body, options, explanation } = quiz;

  // eslint-disable-next-line no-unused-vars
  const [shuffled, setShuffled] = useState(setOrder(options));

  const handleChoice = (option) => {
    if (hasSubmitted) {
      return;
    }

    setChoice(option);
  };

  const handleSubmit = async () => {
    const result = choice.correct ? "success" : "failure";

    setLessonLoading(true);
    setReviewLoading(true);
    if (window.CSSMasterClass) {
      await sendAttemptResult(
        window.CSSMasterClass.courseId,
        id,
        result,
        window.CSSMasterClass.token,
      );
    }
    setLessonLoading(false);
    setReviewLoading(false);
    setSubmitted(true);

    setTimeout(() => {
      window.scrollTo({
        top: window.document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 50);
  };

  const canSubmit = choice.value !== "" && !hasSubmitted;
  const correct = hasSubmitted && choice.correct;
  const wrong = hasSubmitted && !choice.correct;

  let output = correct ? "Correct!" : `Incorrect.`;

  if (wrong) {
    output += " ";
    output += choice.explanation || explanation || "";
  }

  const showContinue = index === currentPart - 1 || index === currentIndex;

  const buttonCN = classNames({
    ["button is-outlined"]: true,
    [cn.submit]: true,
  });

  const resultCN = classNames({
    [cn.result]: true,
    [cn.correct]: correct,
    [cn.wrong]: wrong,
  });

  return (
    <div className={cn.quiz} data-type="question">
      <div className={cn.header}>
        <Markdown markdown={question} />
      </div>

      {body && <div className={cn.body}>{body}</div>}

      <div className={cn.options}>
        {shuffled.map((option) => {
          const chosen = option.value === choice.value;
          const wrong = chosen && hasSubmitted && !option.correct;
          const correct = !chosen && hasSubmitted && option.correct;

          let icon = "circle";

          if (wrong) {
            icon = "xmark";
          } else if (correct || chosen) {
            icon = "circle-check";
          }

          const codeCN = classNames({
            ["toggle"]: true,
            ["toggle-checked"]: chosen,
            ["toggle-wrong"]: wrong,
            ["toggle-correct"]: correct,
            ["toggle-disabled"]: hasSubmitted,
            [cn.toggle]: true,
          });

          return (
            <code
              key={option.value}
              className={codeCN}
              onClick={() => handleChoice(option)}
            >
              <div className="toggle-svg">
                <SVG icon={icon} />
              </div>
              <span>
                <Markdown markdown={option.value} />
              </span>
              {correct && <span className={cn.label}>Correct Answer</span>}
            </code>
          );
        })}
      </div>

      {!hasSubmitted && (
        <button
          disabled={!canSubmit || somethingIsLoading}
          onClick={handleSubmit}
          className={buttonCN}
        >
          <span className="button-label">Submit</span>
        </button>
      )}

      {hasSubmitted && (
        <div className={resultCN}>
          <Markdown markdown={output} />
        </div>
      )}

      {hasSubmitted && showContinue && (
        <button onClick={handleContinue} className={buttonCN}>
          <span className="button-label">Continue</span>
        </button>
      )}
    </div>
  );
};

Quiz.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  quizJSON: PropTypes.string,
};

export default Quiz;
